<template>
  <div>
    <b-modal
      :id="componentName"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      @shown="btnModal('show')"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          롤링금 산정 내역
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click.prevent="btnModal('close')"
          />
        </div>
      </template>

      <!-- Modal Body -->
      <b-card no-body>

        <div>
          <div
            class="summary-container mt-2"
            style="background-color: #24233a; color: rgb(196,186,138); border-radius: 5px;"
          >
            <table
              class="table table-sm text-center"
              style="color: rgb(196,186,138);"
            >
              <thead>
                <tr>
                  <th>유저</th>
                  <th>닉네임</th>
                  <th>추천인</th>
                  <th>베팅금 합계</th>
                  <th>롤링금 합계</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="user in userRollingSummary"
                  :key="user.userid"
                >
                  <td
                    class="col-2"
                    style="text-align: left; padding-left: 20px;"
                  >
                    <span :style="{ paddingLeft: `${(user.level - 1) * 20}px` }">
                      {{ user.userid }}
                    </span>
                  </td>
                  <td class="col-2">
                    {{ user.nickname }}
                  </td>
                  <td class="col-2">
                    {{ user.recommendNickname }}
                  </td>
                  <td style="color:rgb(196,186,138);">
                    {{ user.totalBettingAmount.toLocaleString() }}
                  </td>
                  <td style="color:rgb(196,186,138);">
                    {{ user.totalRollingAmount.toLocaleString() }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr style="background-color: #24233a; color: rgb(196,186,138); font-weight: bold;">
                  <td
                    colspan="3"
                    class="text-center"
                  >
                    총합
                  </td>
                  <td style="color:rgb(196,186,138);">
                    {{ totalSummary.totalBettingAmount.toLocaleString() }}
                  </td>
                  <td style="color:rgb(196,186,138);">
                    {{ totalSummary.totalRollingAmount.toLocaleString() }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <!-- Table Fitler -->
          <div class="mb-2">
            <!-- Search Condition -->
            <b-row>
              <b-col sm="3">
                <b-form-group
                  v-if="false"
                  class="font-small-3 mt-lg-1"
                  label="적용일시"
                  label-for="issueDate"
                  label-align-sm="2"
                  content-cols-sm="6"
                >
                  <b-form-select
                    id="issueDate"
                    ref="issueDate"
                    v-model="selectedIssueDate"
                    :options="issueDateOptions"
                    class="font-small-3"
                    style="color:rgb(229,166,48)"
                  >
                    <template #first>
                      <option
                        value=""
                        style="color: white;"
                      >
                        == 전체 ==
                      </option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col
                offset-sm="3"
                sm="6"
              >
                <b-form-group
                  v-if="false"
                  class="font-small-3 mt-lg-1"
                  label="검색"
                  label-for="filterInput"
                  label-align-sm="2"
                  content-cols-sm="10"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="searchFilter"
                      type="search"
                      placeholder=""
                      style="color: #99dff1"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!searchFilter"
                        @click="searchFilter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <!-- table content -->
          <div>
            <b-table
              responsive
              show-empty
              small
              empty-text="No matching records found"
              style="text-align: center; font-size: small"
              :items="filteredItems"
              :fields="fields"
            >
              <!-- Table Body -->
              <template #cell(index)="data">
                <span v-if="data.item.seq === 0">
                  {{ Math.floor(data.index / 2) + 1 }}
                </span>
              </template>
              <template #cell(baseDate)="data">
                <span v-if="data.item.seq === 0">
                  {{ data.item.baseDate }}
                </span>
              </template>

              <template #cell(user)="data">
                <div class="d-flex align-items-center">
                  <div
                    class="d-flex align-items-center justify-content-end flex-shrink-0"
                    style="width: 30%; margin-right: 5px;"
                  >
                    <b-badge
                      v-if="data.item.seq === 0"
                      :variant="`light-primary`"
                      class="me-2"
                    >
                      상위
                    </b-badge>
                  </div>
                  <div
                    class="d-flex align-items-center"
                    style="width: 70%;"
                  >
                    {{ data.item.userid }}
                  </div>
                </div>
              </template>

              <template #cell(gameType)="data">
                <span>
                  {{ data.item.gameType }}
                </span>
              </template>
              <template #cell(gameDetail)="data">
                <div
                  style="min-width: 10rem;"
                >
                  <span>
                    {{ data.item.gameDetail }}
                  </span>
                </div>
              </template>
              <template #cell(betDate)="data">
                <div
                  style="min-width: 10rem;"
                >
                  <span>
                    {{ data.item.betDate }}
                  </span>
                </div>
              </template>
              <template #cell(issueDate)="data">
                <div
                  style="min-width: 10rem;"
                >
                  <span>
                    {{ data.item.issueDate }}
                  </span>
                </div>
              </template>
              <template #cell(bettingAmount)="data">
                <div class="d-flex nowrap-text">

                  <span
                    class="text-right w-100"
                    style="color:rgb(196,186,138);"
                  >
                    <!-- {{ data.item.bettingAmount !== null ? (data.item.bettingAmount).toLocaleString() : null }} -->
                    {{ data.item.bettingAmount }}
                  </span>
                </div>
              </template>
              <template #cell(rate)="data">
                <div class="nowrap-text">
                  <span style="color:rgb(196,186,138); min-width: 6rem">{{ data.item.rate }} </span>
                </div>
              </template>
              <template #cell(rollingAmount)="data">
                <div class="d-flex nowrap-text">
                  <span
                    class="text-right w-100"
                    style="color:rgb(196,186,138);"
                  >
                    <!-- {{ data.item.rollingAmount !== null ? (data.item.rollingAmount).toLocaleString() : null }} -->
                    {{ data.item.rollingAmount }}
                  </span>
                </div>
              </template>
              <template #cell(formula)="data">
                <div
                  style="min-width: 10rem;"
                >
                  <span>
                    {{ data.item.formula }}
                  </span>
                </div>
              </template>

              <!-- Table Footer -->
              <template
                slot="bottom-row"
              >
                <td
                  colspan="7"
                  class="text-center"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  Total
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('bettingAmount').toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('rollingAmount').toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
              </template>
            </b-table>
          </div>

        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard, BRow, BCol, BButton,
  BFormGroup,
  BFormInput, BInputGroupAppend, BInputGroup,
  BFormSelect,
  BTable,
  VBTooltip,
  BBadge, BMedia, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')

export default {
  name: 'BranchCalculateDailyUsersRolling',
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BTable,
    BBadge,
    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    fetchBalanceUserRolling: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
      avatarText,
      // TableFilter
      selectedIssueDate: '',
      searchFilter: '',

      // Tablefields
      fields: [
        { key: 'index', label: 'No.', thClass: '' },
        { key: 'baseDate', label: '기준일자', thClass: '' },
        { key: 'user', label: '유저', thClass: '' },
        { key: 'gameType', label: '게임', thClass: '' },
        { key: 'gameDetail', label: '게임상세', thClass: '' },
        { key: 'betDate', label: '베팅일자', thClass: '' },
        { key: 'issueDate', label: '정산일자', thClass: '' },
        { key: 'bettingAmount', label: '배팅금', thClass: '' },
        { key: 'rate', label: '요율', thClass: '', tdClass: 'text-center' },
        { key: 'rollingAmount', label: '롤링금', thClass: '' },
        { key: 'formula', label: '계산식', thClass: '', tdClass: 'text-left' },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    filteredItems() {
      // let filteredData = this.fetchBalanceUserRolling

      const groupedData = this.fetchBalanceUserRolling.reduce((acc, item) => {
        if (!acc[item.gameId]) {
          acc[item.gameId] = []
        }
        acc[item.gameId].push(item)
        return acc
      }, {})

      // 각 그룹 내에서 seq를 역순으로 재설정
      const result = Object.values(groupedData).flatMap(group => group
        .reverse() // 그룹을 역순으로 변경
        .map((item, index) => ({
          ...item,
          seq: index, // seq 값을 재설정
        })))

      let filteredData = result

      if (this.searchFilter) {
        filteredData = filteredData.filter(item => Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase()),
        ))
      }

      return filteredData
    },
    userRollingSummary() {
      const summaryData = this.fetchBalanceUserRolling.reduce((acc, item) => {
        // `userid`가 없으면 초기화
        if (!acc[item.userid]) {
          acc[item.userid] = {
            userid: item.userid,
            nickname: item.nickname,
            recommendNickname: item.recommendNickname,
            totalBettingAmount: 0,
            totalRollingAmount: 0,
          }
        }

        // 합계 계산 (bettingAmount가 null일 경우 0 처리)
        acc[item.userid].totalBettingAmount += item.bettingAmount ?? 0
        acc[item.userid].totalRollingAmount += item.rollingAmount ?? 0

        return acc
      }, {})
      const sortedUsers = this.buildHierarchy(Object.values(summaryData))
      console.log('1111', JSON.stringify(sortedUsers))
      return sortedUsers
    },
    totalSummary() {
      if (!this.fetchBalanceUserRolling || this.fetchBalanceUserRolling.length === 0) {
        return { totalBettingAmount: 0, totalRollingAmount: 0 } // 데이터가 없을 경우 기본값 반환
      }

      return this.fetchBalanceUserRolling.reduce(
        (acc, item) => {
          acc.totalBettingAmount += item.bettingAmount ?? 0
          acc.totalRollingAmount += item.rollingAmount ?? 0
          return acc
        },
        { totalBettingAmount: 0, totalRollingAmount: 0 },
      )
    },

  },
  methods: {
    // Modal
    btnModal(target) {
      if (target === 'close') {
        // console.log(this.componentName, 'btnModal()', target)
        this.$bvModal.hide(this.componentName)
      }
    },

    // fnc
    /* Table Variant */
    rowClass(item, type) {
      // baseDate 값에 따라 다른 클래스를 반환
      if (type === 'row') {
        // issueDate에서 시간 부분만 추출 (HH:mm:ss)
        const time = item.issueDate.split(' ')[1]

        // 분(minute) 부분을 추출
        const minutes = parseInt(time.split(':')[1], 10)

        // 분을 5로 나누었을 때 나머지가 짝수면 table-bright', 홀수면 'table-dark'' 클래스를 적용
        return (minutes / 5) % 2 === 0 ? 'table-bright' : 'table-dark'
      }
    },
    buildHierarchy(data) {
      console.log('22222', data)
      const userMap = {}

      // 닉네임을 키로 한 빠른 조회용 객체 생성
      data.forEach(user => {
        userMap[user.nickname] = { ...user, children: [] }
      })

      const rootNodes = []

      // 부모-자식 관계 정리
      data.forEach(user => {
        const parent = userMap[user.recommendNickname]
        if (parent) {
          parent.children.push(userMap[user.nickname])
        } else {
          rootNodes.push(userMap[user.nickname]) // 최상위 노드
        }
      })

      // ✅ Level 추가 (불변성 유지)
      function assignLevel(nodes, level = 1) {
        return nodes.map(node => ({
          ...node, // 기존 객체 복사
          level,
          children: node.children.length > 0 ? assignLevel(node.children, level + 1) : [],
        }))
      }

      const hierarchicalData = assignLevel(rootNodes)

      // ✅ 계층 구조를 평탄화하여 정렬된 리스트 반환
      function flattenHierarchy(nodes) {
        return nodes.reduce((acc, node) => {
          acc.push({
            userid: node.userid,
            nickname: node.nickname,
            recommendNickname: node.recommendNickname,
            totalBettingAmount: node.totalBettingAmount,
            totalRollingAmount: node.totalRollingAmount,
            level: node.level,
          })
          if (node.children.length > 0) {
            acc.push(...flattenHierarchy(node.children))
          }
          return acc
        }, [])
      }

      return flattenHierarchy(hierarchicalData)
    },

    /* [formatter] */
    formatTime(value) {
      return moment(value, moment.ISO_8601, true).isValid() ? moment(value).format('HH시 mm분') : value
    },
    formatNumberWithCommas(value) {
      if (value == null || Number.isNaN(Number(value))) {
        return value
      }

      return parseInt(value, 10).toLocaleString()
    },
    getTotal(field) {
      return this.filteredItems.reduce((acc, item) => acc + item[field], 0)
    },
    _getTotal(category, field) {
    // category: sport, casino, slot
    // field: bettingAmount or rollingAmount
      return this.filteredItems.reduce((acc, item) => {
        if (item[category] && item[category][field] !== undefined) {
          return acc + item[category][field]
        }
        return acc
      }, 0)
    },
  },

}
</script>

<style>
  .modal-dialog {
    max-width: 1200px;
  }
</style>
